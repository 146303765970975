import { FC, useEffect, createContext, useContext, useState, useCallback, PropsWithChildren } from 'react';

import { getSetting } from '../../settings'
import { ErrorJombtron } from '../general/error-message';
import { SignIn } from '../../pages/autenticate/sign-in';

interface UserPermissions {
  provider: boolean;
  loading: boolean;
  autenticated: boolean;
  permissions: string[];
  error?: Error;
  refresh: () => void;
  logout: () => void;
}

const AutenticateContext = createContext<UserPermissions>({ provider: false, loading: true, autenticated: false, permissions: [], refresh() { }, logout() { } });

export function useAutenticate() {
  const context = useContext(AutenticateContext);
  if (!context.provider) {
    throw new Error('The component using this context must be a descendant of the context provider');
  }

  return context;
}

export const AutenticateProvider: FC = props => {
  const [permissions, setPermissions] = useState<UserPermissions>({ provider: true, loading: true, autenticated: false, permissions: [], refresh() { }, logout() { } });
  
  const fetchAutenticated = useCallback(() => {
    const fetchLogout = () => {
      fetch(getSetting('server.context') + '/api/app/authentication/logout')
        .then(() => {
          setPermissions({ provider: true, loading: false, autenticated: false, permissions: [], refresh: fetchAutenticated, logout: () => null });          
          window.history.replaceState({}, '', '/');
        }).catch(err => {
          setPermissions({ provider: true, loading: false, autenticated: false, permissions: [], error: err, refresh: fetchAutenticated, logout: fetchLogout });
        })
    }

    fetch(getSetting('server.context') + '/api/app/authorization/permissions/current')
      .then(response => response.json())
      .then((userPermissions) => {
        setPermissions({
          provider: true,
          loading: false,
          autenticated: userPermissions.autenticated,
          permissions: userPermissions.permissions,
          refresh: fetchAutenticated,
          logout: fetchLogout
        });
      })
      .catch(err => {
        setPermissions({ provider: true, loading: false, autenticated: false, permissions: [], error: err, refresh: fetchAutenticated, logout: fetchLogout });
      })
}, [setPermissions]);

  useEffect(() => {
    fetchAutenticated();
  }, [fetchAutenticated]);

  return (
    <AutenticateContext.Provider value={permissions}>
      {props.children}
    </AutenticateContext.Provider>
  );
}


interface AutenticatedOptions {
  showError?: boolean;
  login?: boolean;
}


export const IsAutenticated: FC<PropsWithChildren<AutenticatedOptions>> = props => {
  const userPermission = useAutenticate();
  if (userPermission.loading) {
    return <></>;
  }

  if (userPermission.autenticated) {
    return <>{props.children}</>;
  } else if (props.login) {
    return <SignIn />
  } else if(props.showError) {
    return <ErrorJombtron error={{errorCode: 401, errorMessage: "Please sign in."}}/>
  } else {
    return <></>;
  }
}

export const IsNotAutenticated: FC = props => {
  const userPermissions = useAutenticate();
  if(userPermissions.loading) {
    return <></>;
  } else {
    return <> {!userPermissions.autenticated && props.children}</>;
  }
}