import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { LoadingFallback } from "../components/general/loading";
import { ErrorBoundary } from "../components/general/error-boundary";
import { getSetting } from "../settings";
import { ErrorJombtron } from "../components/general/error-message";
import { SignIn } from "./autenticate/sign-in";
import { SignOut } from "./autenticate/sign-out";
import PrivacyPolicy from "./privacy-policy";

const Welcome = lazy(() => import("./welcome"));
const LoginWizard = lazy(() => import("./autenticate/login/login-wizard"));
const RecoverPassword = lazy(() => import("./user/recover-password"));
const ChangePassword = lazy(() => import("./user/change-password"));
const DeleteAccount = lazy(() => import("./user/delete-account"));
const Email = lazy(() => import("./user/email"));
const EmailAdd = lazy(() => import("./user/email-add"));
const EmailValidate = lazy(() => import("./user/email-validate"));
const Federation = lazy(() => import("./user/federation"));
const UserInformation = lazy(() => import("./user/information"));

const PAGE_NOT_FOUND = { errorCode: 404, errorMessage: 'Can not find any page matching browser address (URL).' };
const serverContext = getSetting('server.context');

class Navigate {
    private path: string;
    constructor(path: string) { this.path = path; }
    public getPath(): string { return this.path; }
}

export const Pages = {
    home: new Navigate(serverContext + "/"),
    privacyPolicy: new Navigate(serverContext + "/privacypolicy"),

    signin: new Navigate(serverContext + "/signin"),
    signout: new Navigate(serverContext + "/signout"),


    autenticatePassword: new Navigate(serverContext + "/autenticate/password"),

    information: new Navigate(serverContext + "/user/information"),
    delete: new Navigate(serverContext + "/user/delete"),
    email: new Navigate(serverContext + "/user/email"),
    emailAdd: new Navigate(serverContext + "/user/email/add"),
    emailValidate: new Navigate(serverContext + "/user/email/validate"),
    federation: new Navigate(serverContext + "/user/federation"),
    passwordChange: new Navigate(serverContext + "/user/password/change"),
    passwordRecover: new Navigate(serverContext + "/user/password/recover"),
}

export function PageNavigation() {
    return (
        <main>
            <ErrorBoundary>
                <Suspense fallback={<LoadingFallback />}>
                    <Routes>
                        <Route path='/' element={<Welcome />} />
                        <Route path={Pages.home.getPath()} element={<Welcome />} />
                        <Route path={Pages.privacyPolicy.getPath()} element={<PrivacyPolicy />} />
                        <Route path={Pages.signin.getPath()} element={<SignIn />} />
                        <Route path={Pages.signout.getPath()} element={<SignOut />} />

                        <Route path={Pages.autenticatePassword.getPath()} element={<LoginWizard />} />

                        <Route path={Pages.information.getPath()} element={<UserInformation />} />
                        <Route path={Pages.delete.getPath()} element={<DeleteAccount />} />
                        <Route path={Pages.email.getPath()} element={ <Email />} />
                        <Route path={Pages.emailAdd.getPath()} element={<EmailAdd />} />
                        <Route path={Pages.emailValidate.getPath()} element={<EmailValidate />} />
                        <Route path={Pages.federation.getPath()} element={<Federation />} />
                        <Route path={Pages.passwordChange.getPath()} element={<ChangePassword />} />
                        <Route path={Pages.passwordRecover.getPath()} element={<RecoverPassword />} />

                        <Route path='*' element={<ErrorJombtron error={PAGE_NOT_FOUND} />} />
                    </Routes>
                </Suspense>
            </ErrorBoundary>
        </main>
    );
}