import { Container } from 'react-bootstrap';
import { useAutenticate } from '../../components/auth/autenticate'

export function SignOut() {
  useAutenticate().logout();

  return (
    <Container>
      <h1>Logout</h1>
      <p>Thank you for using this service.</p>
    </Container>
  );
}