import { FC, useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";

export interface ErrorMessageOptions {
  errorCode: number;
  errorTitle?: string;
  errorMessage: unknown;
}

interface ErrorMessageOptional {
  error: ErrorMessageOptions | null | undefined
}

export const ErrorJombtron: FC<ErrorMessageOptional> = props => {
  if (!props.error) {
    return <></>;
  }

  const title: string = getTitle(props.error);

  return (
    <Container>
      <div className="container-fluid p-5 mb-5" style={{backgroundColor: "#e9ecef"}} >
        <h1>{title}</h1>
        <p>{props.error.errorMessage instanceof  Error ? props.error.errorMessage.message : "An error occured. Please try again later." }</p>
      </div>
    </Container>
  )
}

export const ErrorAlert: FC<ErrorMessageOptional> = props => {
  const [show, setShow] = useState(true);
  
  useEffect(() => {
    setShow(true);
  }, [props.error]);

  if (!props.error) {
    return <></>;
  }

  return (
    <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
      <strong>{getTitle(props.error)}</strong>
        <p>{props.error.errorMessage instanceof  Error ? props.error.errorMessage.message : "An error occured. Please try again later." }</p>
    </Alert>
  )
}


function getTitle(props: ErrorMessageOptions) {
  let title: string;

  if (props.errorTitle) {
    title = props.errorTitle;
  } else {
    switch (props.errorCode) {
      case 401:
        title = 'Unauthorized';
        break;
      case 403:
        title = 'Forbidden';
        break;
      case 404:
        title = 'Not found';
        break;
      case 500:
        title = 'Internal server error';
        break;
      default:
        title = props.errorCode.toString();

    }
  }

  return title;
}
