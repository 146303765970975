import { Container } from "react-bootstrap";

export function PrivacyPolicy() {

    return (
        <Container>
            <h1>Privacy Policy</h1>

            <h2>Introduction</h2>
            <p>This document describes our privacy policy and how your personal information is taken care of.
                Your privacy matters and you should take the time too read this document.
                This solution is made based on personal needs and interests
                and if you have any questions or comments to this documents can be sent too post@vevik.no.
            </p>

            <h2>Collected Information</h2>
            <p> Information collected will be related too authentication and services on this website. </p>

            <h3>Cookies</h3>
            <p>At this site cookie for the following purposes:</p>

            <ul>
                <li>Authentication - we use cookie to identify you when you visit our website and as you navigate our website.</li>
                <li>Status - we use cookie to help us to determine if you are logged into our website.</li>
                <li>Analysis - we use cookie to help us to analyse the use and performance of our website and services.</li>
            </ul>
            <br/>
            <p>Site cookie used as listed above is stored in your browser with name JSESSIONID and account.sid.</p>

            <h3>Personal information</h3>
            <p>At this site we process the following personal information:</p>

            <ul>
                <li>Account data - the account data include your name and email address and other data directly associated to you as a person.
                The source of the account data is you or third party authentication services like Google and Facebook.
                The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you.
                The legal basis for this processing is consent and proper administration of our website.</li>
                <li>Service data - the service data are information linked to your account data and
                is required for delivering each service. The source of service data is input from usage of the website.
                The legal basis for this processing is consent and proper administration of our website.</li>

                <li>Usage data - the usage data may include your IP address, geographical location,
                browser type and version, operating system, referral source, length of visit,
                page views and website navigation paths, as well as information about the timing,
                frequency and pattern of your service use. The source of the usage data is our analytics tracking system.
                This usage data may be processed for the purposes of analysing the use of the website and services].
                The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services. </li>
            </ul>
            <br/>
            <h2>Sharing personal data</h2>

            <p>Financial transactions relating to this website and services may be handled by our payment services providers,
            We will share transaction data with our payment services providers only to the extent necessary for the purposes
            of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds.
            </p>

            <h3>Storing personal data</h3>
            <p>Personal information will be stored on-premise (Norway), and in future data can be relocated to a cloud service.
            </p>
        </Container>
    );
}

export default PrivacyPolicy;