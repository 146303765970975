import { Component, ErrorInfo, ReactNode } from "react";

import { ErrorJombtron } from './error-message'

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorJombtron error={{errorCode: 500, errorMessage: 'Sorry uncaught error occured'}} />;
    }

    return this.props.children;
  }
}
