import { createContext, FC, useContext, useState } from "react";
import { Toast } from "react-bootstrap";

interface ToastMessage {
    title: string;
    message: string;
}

interface ToastKey {
    key: string;
}


interface ToastMessages {
    provider: boolean;
    toasts: Array<ToastMessage & ToastKey>;
    add(message: ToastMessage): void;
    remove(message: ToastMessage): void;
}

const ToastContext = createContext<ToastMessages>({ provider: false, toasts: [], add(toast: ToastMessage) { }, remove(message: ToastMessage) { } });

export function useToastMessage() {
    const context = useContext(ToastContext);
    if (!context.provider) {
        throw new Error('The component using this context must be a descendant of the context provider');
    }

    return context;
}

export const ToastProvider: FC = props => {
    const [toastMessages, setToastMessages] = useState<ToastMessages>({ provider: true, toasts: [], add(toast: ToastMessage) { }, remove(message: ToastMessage) { } });
    const [key, setKey] = useState<number>(0);

    toastMessages.add = function add(toast: ToastMessage & ToastKey) {
        setKey(key + 1);
        const tempToast = toast.key ? toast : Object.assign({ key: key }, toast);
        const tempToasts = { provider: true, toasts: this.toasts, add: this.add, remove: this.remove };
        tempToasts.toasts.push(tempToast);
        setToastMessages(tempToasts);
    }

    toastMessages.remove = function remove(toast: ToastMessage & ToastKey) {
        const index = this.toasts.indexOf(toast);
        if (index >= 0) {
            const temp = { provider: true, toasts: this.toasts, add: this.add, remove: this.remove };
            temp.toasts.splice(index, 1);
            setToastMessages(temp);
        }
    }

    return (
        <ToastContext.Provider value={toastMessages}>
            <ShowToastMessage />
            {props.children}
        </ToastContext.Provider>
    );
}

const ShowToastMessage: FC = props => {
    const toastContext = useToastMessage();

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                top: 80,
                right: 16,
                position: 'fixed',
                zIndex: 1,
                minWidth: '400px',
                minHeight: '200px',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            >
                {
                    toastContext.toasts.map((toast) =>
                        <Toast key={toast.key} onClose={() => { toastContext.remove(toast); }}  delay={6000} autohide >
                            <Toast.Header>
                                <strong className="me-auto">{toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{toast.message}</Toast.Body>
                        </Toast>)
                }
            </div>
        </div>
    )
};