import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router'

import { getSetting } from '../../settings'

export function windowLogin(path?: string) {
  const backendUrl = '' + getSetting('server.url') + getSetting('server.context');

  if (!path || path.endsWith('/signin')) {
    window.location.href = backendUrl + '/api/app/authentication/openid'
  } else {
    window.location.href = backendUrl + '/api/app/authentication/openid?forward=' + path;
  }
}

export function SignIn() {
  const [path] = useState(useLocation().pathname);

  useEffect(() => {
    async function fetchSettings() {
      windowLogin(path);
    }
    fetchSettings();

  }, [path]);

  return (<Container><Row><Col>Redirect to login page ...</Col></Row></Container>);
}