import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import { IsAutenticated, IsNotAutenticated } from "../components/auth/autenticate";
import { getSetting } from '../settings';
import { Pages } from './page-navigation';

export function PageHeader() {
    const serverContext = getSetting('server.context');
    return (
        <header className="pb-5">
            <Navbar expand="md" bg="dark" variant="dark">
            <Container fluid>
                <Navbar.Brand as={Link} to={serverContext + "/"}>Your Account</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            <IsNotAutenticated>
                                <Nav.Link as={Link} to={Pages.privacyPolicy.getPath()}>Privacy policy</Nav.Link>
                            </IsNotAutenticated>
                            <IsAutenticated>
                                <Nav.Link as={Link} to={Pages.signout.getPath()}>Sign out</Nav.Link>
                            </IsAutenticated>
                        </Nav>
                    </Navbar.Collapse>
            </Container>
            </Navbar>
        </header>
    );
}