import { FC, PropsWithChildren, Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactLoading from 'react-loading';

import { ErrorJombtron, ErrorMessageOptions } from "./error-message";

interface LoadingOptions {
    error?: ErrorMessageOptions | null;
    loading?: boolean | null;
}

export const Loading: FC<PropsWithChildren<LoadingOptions>> = props => {
    if (props.error) {
        return <ErrorJombtron error={props.error} />
    } else if (props.loading) {
        return <LoadingFallback/>        
    } else {
        return <Suspense fallback={<LoadingFallback/>}>{props.children}</Suspense>
    }
}

export function LoadingFallback() {
    return (
        <Container fluid>
            <Row className="mt-5" >
                <Col xs={12} className="d-flex justify-content-center">
                    <ReactLoading type={'bubbles'} color={'Silver'} height={'100px'} width={'100px'} delay={250} />
                </Col>
            </Row>
        </Container>
    );
}