import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { AutenticateProvider } from './components/auth/autenticate';
import { PageHeader } from './pages/page-header';
import { ToastProvider } from './components/general/toast-messages';
import { PageNavigation } from './pages/page-navigation';

function App() {
  document.body.classList.add('bg-light');

  return (
    <AutenticateProvider>
      <ToastProvider>
        <PageHeader />
        <PageNavigation />
      </ToastProvider>
    </AutenticateProvider>
  );
}

export default App;
